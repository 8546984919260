{
  "name": "exisim",
  "version": "4.0.0",
  "browserslist": "> 1%, last 2 versions, not dead",
  "description": "Partially scientific zero-player human existence simulator",
  "author": {
    "name": "Zefir Efemera",
    "email": "zefirefemera@proton.me"
  },
  "private": false,
  "homepage": "https://github.com/magicznyleszek/exisim",
  "repository": "git+https://github.com/magicznyleszek/exisim.git",
  "bugs": "https://github.com/magicznyleszek/exisim/issues",
  "license": "EUPL-1.2",
  "scripts": {
    "test": "jest && npm run test-syntax",
    "test-syntax": "prettier --check source",
    "test-watch": "jest --watchAll",
    "start": "parcel -p 2038 source/index.html --dist-dir dev",
    "build-app": "rm -rf public && parcel build source/index.html --dist-dir public --public-url ./ --no-cache && npm run copy-static-files",
    "copy-static-files": "cp humans.txt LICENSE manifest.json robots.txt sitemap.xml source/favicons/exisim-logo.svg public"
  },
  "devDependencies": {
    "@types/jest": "^29.5.12",
    "@types/lodash": "^4.17.7",
    "@types/mixpanel-browser": "^2.49.1",
    "@typescript-eslint/eslint-plugin": "^8.0.1",
    "@typescript-eslint/parser": "^8.0.1",
    "eslint": "^9.8.0",
    "eslint-config-prettier": "^9.1.0",
    "jest": "^29.7.0",
    "jest-environment-jsdom": "^29.7.0",
    "mixpanel-browser": "^2.55.0",
    "parcel": "^2.12.0",
    "parcel-packager-svg-sprite": "^2.0.1",
    "parcel-transformer-svg-sprite": "^2.0.1",
    "prettier": "^3.3.3",
    "ts-jest": "^29.2.4",
    "typescript": "^5.5.4"
  },
  "jest": {
    "testEnvironment": "jsdom",
    "collectCoverage": true,
    "coverageThreshold": {
      "global": {
        "branches": 80,
        "functions": 80,
        "lines": 80,
        "statements": 80
      }
    },
    "transform": {
      "^.+\\.ts?$": "ts-jest"
    },
    "moduleNameMapper": {
      "\\.(gif|ttf|eot|svg)$": "<rootDir>/__mocks__/file-mock.js"
    },
    "testRegex": "(/__tests__/.*|(\\.|/)(test|spec))\\.(js?|ts?)$",
    "moduleFileExtensions": [
      "ts",
      "tsx",
      "js",
      "jsx",
      "json",
      "node"
    ]
  }
}
